/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield !important;
}

.table>tbody>tr>td {
    vertical-align: middle !important;
}

div.legendaBG {
    min-height: 10px;
    min-width: 10px;
}

.bg-praznik-vikend {
    background: repeating-linear-gradient( 45deg, #236066, #236066 6px, #25358d 6px, #25358d 12px) !important;
}

.bg-praznik-radni {
    background: repeating-linear-gradient( 45deg, #6c757d, #6c757d 6px, #25358d 6px, #25358d 12px) !important;
}

.bg-radni-buducnost{
    background-color: #98a4ae !important;
}

.bg-radni-proslost{
    background-color: #6c757d !important;
}
.bg-odobreno{
    background-color: #198754 !important;
}

.bg-najavljeno{
    background-color: #cc6e36 !important;
}

.bg-iskoristeno{
    background-color: #a6333e !important;
}

.bg-vikend{
    background-color: #236066 !important;
}

.opaque {
    opacity: 0.8;
    border: 1px black;
}

body {
    background: #65658a;
}

.pointer {
    cursor: pointer;
}

.sticky-col {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
}
